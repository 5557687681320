
import { onAuthStateChanged } from 'firebase/auth';
import { onValue, ref, set } from 'firebase/database';
import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { studentAuth, studentDB } from '../../api/firebase-student-config';
import whiteFullLogo from '../../img/logo/white-full-logo.png';
import whiteFullLogoStar from '../../img/logo/white-full-logo-star.png';

import profilePlaceholder from '../../img/placeholder/profile-placeholder.png';

import $ from 'jquery'
import ModalLoading from '../general/ModalLoading';
import ModalNotifications from './ModalNotifications';
import { getNotificationPermission, notifyUser } from '../../api/notification';

export const AcademyNavbar = ()=>{

    var [student,setStudent] = useState(null);
    var [uid,setUID] = useState(null)

    var [innerCircle,setInnerCircle] = useState()

    var location = useLocation()

    var [darkMode,setDarkMode] = useState(false)
    var [processing,setProcessing] = useState(false)
    
    var [notifOpen,setNotifOpen] = useState(false);
    var [unreadNotifs, setUnreadNotifs] = useState([])
    var [upgrade,setUpgrade] = useState(false)

    useEffect(()=>{

        onAuthStateChanged(studentAuth,(student)=>{
        
            if(student){
                
                setUID(student.uid)

                onValue(ref(studentDB,"students/"+student.uid),(snapshot)=>{
                    if(snapshot.exists()){
                        setStudent(snapshot.val());
                        
                        if(snapshot.val().special && snapshot.val().special === "UPGRADE") setUpgrade(true);
                        else setUpgrade(false);

                    }
                })
        
            }
    
        })

        $("html, body").animate({ 
            scrollTop: 0 
        }, "fast");

        if($('.navbar-collapse').hasClass('show')) $('.navbar-toggler').trigger('click')

    },[location,uid])

    useEffect(()=>{

        if(uid){

            onValue(ref(studentDB,'partnershipRequests/' + uid),(snap)=>{
                
                if(snap.exists()){
                    setInnerCircle(snap.val().status)
                }
                else{
                    setInnerCircle('N/A')
                }

            })

            onValue(ref(studentDB,`students/${uid}/theme`),(snapshot)=>{
                if(snapshot.exists()){
                    if(snapshot.val() === 'DARK') setDarkMode(true)
                    else setDarkMode(false)
                }else setDarkMode(false)
            })

            onValue(ref(studentDB,`notifications/${uid}`),(snapshot)=>{

                if(snapshot.exists()){
                    var nfs = []

                    for(var k in snapshot.val()){
                        var n = snapshot.val()[k];
                        n.key = k;
                        
                        if(!n.read) nfs.push(n);
                    }

                    setUnreadNotifs(nfs);
                }

            })

        }

    },[uid])

    useEffect(()=>{

        if(unreadNotifs.length > 0 && uid){
            
            for(var n of unreadNotifs){
                if(!n.notified){
                    notifyUser(n.message,n.link,n.key,uid);
                    set(ref(studentDB,`notifications/${uid}/${n.key}/notified`),true);
                }
            }


        }

    },[unreadNotifs, uid])


    function openNotifications(){
        setNotifOpen(true);
        getNotificationPermission(uid);
    }

    function logOut(){
        studentAuth.signOut().then(()=>window.location.href='/login');
    }

    function toggleTheme(){
        setProcessing(true)
        set(ref(studentDB,`students/${uid}/theme`),darkMode?'LIGHT':'DARK').then(()=>{
            //window.location.reload()
            setProcessing(false)
        })
    }

    if(location && (location.pathname === '/module/view')) return(null)

    if(location && (location.pathname === '/about')) return(null)
    if(location && (location.pathname === '/home')) return(null)
    if(location && (location.pathname === '/support')) return(null)
    if(location && (location.pathname === '/elite-pricing')) return(null)

    if(location && (location.pathname === '/freetraining')) return(null)
    if(location && (location.pathname === '/congratulations')) return(null)
    if(location && (location.pathname === '/services')) return(null)
    if(location && (location.pathname === '/enrollment')) return(null)
    if(location && (location.pathname === '/checkout')) return(null)
    if(location && (location.pathname === '/success')) return(null)
    if(location && (location.pathname.includes('/mini-workshop'))) return(null)
    if(location && (location.pathname === '/inner-circle-copytrading-tutorial')) return(null)

    if(location && (location.pathname === '/freetraining-community')) return(null)

    if(location && (location.pathname === '/lifetime-offer')) return(null)
    if(location && (location.pathname === '/ftph-invitation')) return(null)
    if(location && (location.pathname === '/student-testimonials')) return(null)

    if(location && (location.pathname.includes('/policies'))) return(null)

    if(location && location.pathname.includes('/try')) return null
    if(location && location.pathname.includes('/trading-circle-family-shirt')) return null

    if(location && location.pathname.includes('/three-day-masterclass')) return null

    return(
        <>
        <nav className="navbar sticky-top navbar-expand-lg bg-blue px-3 py-1 animate__animated animate__fadeInDown" style={{borderRadius:"0 0 15px 15px"}}>
            <div className="container-fluid">
                
                <a className="navbar-brand mb-0" href="/">
                    <img src={upgrade?whiteFullLogoStar:whiteFullLogo} width="200px" alt="logo"/>
                </a>
                
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="fa fa-bars"></span>
                </button>
                
                <div className="collapse navbar-collapse" id="navbarText">


                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    </ul>
                    
                    <span className="navbar-text d-block d-lg-none nav-mobile">

                        <div className="row me-auto mb-2 mb-lg-0 justify-content-center" style={{gap:"10px"}}>
                            
                            {/* 
                            <div className="nav-item text-center col-3">
                                <NavLink className={({ isActive }) =>isActive ? 'nav-link active': 'nav-link'} to='/group-chat'>
                                    <i className='fa fa-comments' style={{fontSize:"24px"}}/>
                                    <br/>
                                    Group Chat
                                </NavLink>
                            </div> */
                            }

                            <div className='col-3 text-center'>
                                <div className='row h-100 align-self-center justify-content-center'>
                                    <div className="dropdown align-self-center">
                                        <p className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <img className='me-2 mb-1' style={{borderRadius:"20rem"}} src={student?student.profile.profilePictureURL:profilePlaceholder} alt='profile' width="36px"/>
                                            {unreadNotifs.length > 0 && <i className='fa fa-circle animate__animated animate__pulse animate__infinite' style={{color:'red',position:'absolute',left:'45%',right:'35%',top:'0px',fontSize:'10px'}}/>}
                                        </p>

                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                            <li><a className="dropdown-item pointer" href="/"><i className="fa-solid fa-user"/> Profile</a></li>
                                            <li><a className="dropdown-item pointer" href="#notifications" onClick={()=>openNotifications()}><i className="fa-solid fa-bell"/> Notifications</a></li>
                                            <li><hr className="dropdown-divider"/></li>
                                            <li><a className="dropdown-item pointer" href="/faq"><i className="fa-solid fa-circle-question"/> FAQ</a></li>
                                            <li><a className="dropdown-item pointer" href="/policies"><i className="fa-solid fa-shield-halved"/> Policies</a></li>
                                            <li><hr className="dropdown-divider"/></li>
                                            <li className="dropdown-item pointer" onClick={()=>toggleTheme()}><i className={`fa-solid ${darkMode?'fa-sun':'fa-moon'}`}/> {darkMode?'Light Mode':'Dark Mode'}</li>
                                            <li><hr className="dropdown-divider"/></li>
                                            <li onClick={()=>logOut()}><p className="dropdown-item pointer bold"><i className="fa-solid fa-right-from-bracket"/> Sign Out</p></li>
                                        </ul>

                                    </div>
                                </div>
                            </div>

                            <div className="nav-item text-center col-3 dropdown">
                                <p className={`nav-link dropdown-toggle ${location.pathname.includes('/chat') && 'active'}`} id="dropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className='fa fa-comments' style={{fontSize:"24px"}}/>
                                    <br/>
                                    Chat
                                </p>

                                <ul className="dropdown-menu" aria-labelledby="chatDropdown">
                                    <li><a className="dropdown-item pointer" href="/chat/standard"><i className="fa-solid fa-user"/> Standard</a></li>
                                    
                                    {student && student.level === 'Elite'?
                                    <li><a className="dropdown-item pointer" href="/chat/elite"><i className="fa-solid fa-bolt"/> Elite</a></li>
                                    :
                                    <li style={{opacity:'0.5'}}><p className="dropdown-item"><i className="fa-solid fa-lock"/> Elite</p></li>
                                    }
                                    
                                    {innerCircle === 'Approved'?
                                    <li><a className="dropdown-item pointer" href="/chat/inner-circle"><i className="fa-brands fa-discourse"/> Inner Circle</a></li>
                                    :
                                    <li style={{opacity:'0.5'}}><p className="dropdown-item"><i className="fa-solid fa-lock"/> Inner Circle</p></li>
                                    }
                                </ul>
                                
                            </div>

                            <div className="nav-item text-center col-3">
                                <NavLink className={({ isActive }) =>isActive ? 'nav-link active': 'nav-link'} to='/module'>
                                    <i className='fa fa-chalkboard' style={{fontSize:"24px"}}/>
                                    <br/>
                                    Module
                                </NavLink>
                            </div>

                            <div className="nav-item text-center col-3 dropdown">
                                <p className={`nav-link dropdown-toggle ${location.pathname.includes('/forum') && 'active'}`} id="forumDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className='fa fa-user-tie' style={{fontSize:"24px"}}/>
                                    <br/>
                                    Forum
                                </p>

                                <ul className="dropdown-menu" aria-labelledby="forumDropdown">
                                    <li><a className="dropdown-item pointer" href="/forum/standard"><i className="fa-solid fa-user"/> Standard</a></li>
                                    
                                    {student && student.level === 'Elite'?
                                    <li><a className="dropdown-item pointer" href="/forum/elite"><i className="fa-solid fa-bolt"/> Elite</a></li>
                                    :
                                    <li style={{opacity:'0.5'}}><p className="dropdown-item"><i className="fa-solid fa-lock"/> Elite</p></li>
                                    }
                                </ul>
                                
                            </div>

                            <div className="nav-item text-center col-3">
                                <NavLink className={({ isActive }) =>isActive ? 'nav-link active': 'nav-link'} to='/training'>
                                    <i className='fa fa-clock' style={{fontSize:"24px"}}/>
                                    <br/>
                                    Training
                                </NavLink>
                            </div>

                            <div className="nav-item text-center col-3">
                                <NavLink className={({ isActive }) =>isActive ? 'nav-link active': 'nav-link'} to='/community'>
                                    <i className='fa fa-users' style={{fontSize:"24px"}}/>
                                    <br/>
                                    Community
                                </NavLink>
                            </div>

                            <div className="nav-item text-center col-3">
                                <NavLink className={({ isActive }) =>isActive ? 'nav-link active': 'nav-link'} to='/inner-circle'>
                                    <i className='fa-brands fa-discourse' style={{fontSize:"24px",lineHeight:"1.5"}}/>
                                    <br/>
                                    Inner Circle
                                </NavLink>
                            </div>

                            <div className="nav-item text-center col-3 dropdown">
                                <p className={`nav-link dropdown-toggle ${(location.pathname.includes('/tools') || location.pathname.includes('/career')) && 'active'}`} id="guidesDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className='fa fa-book' style={{fontSize:"24px"}}/>
                                    <br/>
                                    Guides
                                </p>

                                <ul className="dropdown-menu" aria-labelledby="guidesDropdown">
                                    <li><NavLink className="dropdown-item pointer" to="/career"><i className="fa-solid fa-user"/> Career</NavLink></li>
                                    <li><NavLink className="dropdown-item pointer" to="/tools"><i className="fa-solid fa-tools"/> Tools</NavLink></li>
                                </ul>
                            </div>
                            

                            <div className="nav-item text-center col-3">
                                <NavLink className={({ isActive }) =>isActive ? 'nav-link active': 'nav-link'} to='/demo-challenge'>
                                    <i className='fa fa-chess' style={{fontSize:"24px"}}/>
                                    <br/>
                                    Challenge
                                </NavLink>
                            </div>
                            

                        </div>

                    </span>

                    <span className="navbar-text d-none d-lg-block">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0" style={{gap:"20px"}}>
                            
                            {/*
                            <li className="nav-item text-center">   
                                <NavLink className={({ isActive }) =>isActive ? 'nav-link active': 'nav-link'} to='/group-chat'>
                                    <i className='fa fa-comments' style={{fontSize:"24px"}}/>
                                    <br/>
                                    Group Chat
                                </NavLink>
                            </li>
                            */}

                            <li className="nav-item text-center dropdown">
                                <p className={`nav-link dropdown-toggle ${(location.pathname.includes('/tools') || location.pathname.includes('/career')) && 'active'}`} id="guidesDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className='fa fa-book' style={{fontSize:"24px"}}/>
                                    <br/>
                                    Guides
                                </p>

                                <ul className="dropdown-menu" aria-labelledby="guidesDropdown">
                                    <li><NavLink className="dropdown-item pointer" to="/career"><i className="fa-solid fa-user"/> Career</NavLink></li>
                                    <li><NavLink className="dropdown-item pointer" to="/tools"><i className="fa-solid fa-tools"/> Tools</NavLink></li>
                                </ul>
                            </li>

                            <li className="nav-item text-center">
                                <NavLink className={({ isActive }) =>isActive ? 'nav-link active': 'nav-link'} to='/f2f-training'>
                                    <i className='fa fa-users' style={{fontSize:"24px"}}/>
                                    <br/>
                                    F2F Training
                                </NavLink>
                            </li>
                            
                            <li className="nav-item text-center">
                                <NavLink className={({ isActive }) =>isActive ? 'nav-link active': 'nav-link'} to='/training'>
                                    <i className='fa fa-clock' style={{fontSize:"24px"}}/>
                                    <br/>
                                    Training
                                </NavLink>
                            </li>

                            <li className="nav-item text-center">
                                <NavLink className={({ isActive }) =>isActive ? 'nav-link active': 'nav-link'} to='/demo-challenge'>
                                    <i className='fa fa-chess' style={{fontSize:"24px"}}/>
                                    <br/>
                                    Challenge
                                </NavLink>
                            </li>

                            <li className="nav-item text-center">
                                <NavLink className={({ isActive }) =>isActive ? 'nav-link active': 'nav-link'} to='/inner-circle'>
                                    <i className='fa-brands fa-discourse' style={{fontSize:"24px",lineHeight:"1.5"}}/>
                                    <br/>
                                    Inner Circle
                                </NavLink>
                            </li>

                            <li className="nav-item text-center">
                                <NavLink className={({ isActive }) =>isActive ? 'nav-link active': 'nav-link'} to='/community'>
                                    <i className='fa fa-users' style={{fontSize:"24px"}}/>
                                    <br/>
                                    Community
                                </NavLink>
                            </li>

                            <li className="nav-item text-center dropdown">
                                <p className={`nav-link dropdown-toggle ${location.pathname.includes('/forum') && 'active'}`} id="forumDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className='fa fa-user-tie' style={{fontSize:"24px"}}/>
                                    <br/>
                                    Forum
                                </p>

                                <ul className="dropdown-menu" aria-labelledby="forumDropdown">
                                    <li><a className="dropdown-item pointer" href="/forum/standard"><i className="fa-solid fa-user"/> Standard</a></li>
                                    
                                    {student && student.level === 'Elite'?
                                    <li><a className="dropdown-item pointer" href="/forum/elite"><i className="fa-solid fa-bolt"/> Elite</a></li>
                                    :
                                    <li style={{opacity:'0.5'}}><p className="dropdown-item"><i className="fa-solid fa-lock"/> Elite</p></li>
                                    }
                                </ul>
                                
                            </li>

                            <li className="nav-item text-center">
                                <NavLink className={({ isActive }) =>isActive ? 'nav-link active': 'nav-link'} to='/module'>
                                    <i className='fa fa-chalkboard' style={{fontSize:"24px"}}/>
                                    <br/>
                                    Module
                                </NavLink>
                            </li>

                            <li className="nav-item text-center me-5 dropdown">
                                <p className={`nav-link dropdown-toggle ${location.pathname.includes('/chat') && 'active'}`} id="chatDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className='fa fa-comments' style={{fontSize:"24px"}}/>
                                    <br/>
                                    Chat
                                </p>

                                <ul className="dropdown-menu" aria-labelledby="chatDropdown">
                                    <li><a className="dropdown-item pointer" href="/chat/standard"><i className="fa-solid fa-user"/> Standard</a></li>
                                    
                                    {student && student.level === 'Elite'?
                                    <li><a className="dropdown-item pointer" href="/chat/elite"><i className="fa-solid fa-bolt"/> Elite</a></li>
                                    :
                                    <li style={{opacity:'0.5'}}><p className="dropdown-item"><i className="fa-solid fa-lock"/> Elite</p></li>
                                    }
                                    
                                    {innerCircle === 'Approved'?
                                    <li><a className="dropdown-item pointer" href="/chat/inner-circle"><i className="fa-brands fa-discourse"/> Inner Circle</a></li>
                                    :
                                    <li style={{opacity:'0.5'}}><p className="dropdown-item"><i className="fa-solid fa-lock"/> Inner Circle</p></li>
                                    }
                                </ul>
                                
                            </li>


                            <div className='row h-100 align-self-center'>
                                <li className="nav-item dropstart">
                                    <p className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img className='me-4' style={{borderRadius:"20rem"}} src={student?student.profile.profilePictureURL:profilePlaceholder} alt='profile' width="36px"/>
                                        {unreadNotifs.length > 0 && <i className='fa fa-circle animate__animated animate__pulse animate__infinite' style={{color:'red',position:'absolute',left:'55px',top:'5px',fontSize:'10px'}}/>}
                                    </p>

                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <li><a className="dropdown-item pointer" href="/"><i className="fa-solid fa-user"/> Profile</a></li>
                                        <li><a className="dropdown-item pointer" href="#notifications" onClick={()=>openNotifications()}><i className="fa-solid fa-bell"/> Notifications</a></li>
                                        <li><hr className="dropdown-divider"/></li>
                                        <li><a className="dropdown-item pointer" href="/faq"><i className="fa-solid fa-circle-question"/> FAQ</a></li>
                                        <li><a className="dropdown-item pointer" href="/policies"><i className="fa-solid fa-shield-halved"/> Policies</a></li>
                                        <li><hr className="dropdown-divider"/></li>
                                        <li className="dropdown-item pointer" onClick={()=>toggleTheme()}><i className={`fa-solid ${darkMode?'fa-sun':'fa-moon'}`}/> {darkMode?'Light Mode':'Dark Mode'}</li>
                                        <li><hr className="dropdown-divider"/></li>
                                        <li onClick={()=>logOut()}><p className="dropdown-item pointer bold"><i className="fa-solid fa-right-from-bracket"/> Sign Out</p></li>
                                    </ul>
                                </li>
                            </div>

                        </ul>
                    </span>

                </div>

            </div>
        </nav>
        
        <ModalLoading show={processing}/>
        <ModalNotifications show={notifOpen} onHide={()=>setNotifOpen(false)}/>

        </>
    )
}