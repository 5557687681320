import { Helmet } from 'react-helmet-async';
import { Header } from '../../components/general/Header';
import { useEffect, useState } from 'react';
import { Footer } from '../../components/academy/Footer';
import ModalLoading from '../../components/general/ModalLoading';
import compoundingPlan from '../../img/career/compounding-plan.webp';
import { onAuthStateChanged } from 'firebase/auth';
import { studentAuth, studentDB } from '../../api/firebase-student-config';
import { onValue, ref } from 'firebase/database';
import { adminDB } from '../../api/firebase-admin-config';
import moment from 'moment';


export const LowTicketAcademy = (props) =>{

    let [processing,setProcessing] = useState(false)

    let [selectedDay,setSelectedDay] = useState(0);
    let [selectedVideo,setSelectedVideo] = useState(0);

    let [dayTitle] = useState([
        "Forex Foundations: Your First Step to Wealth!",
        "Trade Smarter: Master Your Platform Fast!",
        "Chart Like a Pro: Unlock Winning Strategies!"
    ])

    let [videoTitles] = useState([
        ["How Forex Works","Setting Lot Sizes","Advantages of Forex","Newbie Common Mistakes"],
        ["Setting up FXPrimus","Reading Pips","Pending Orders","Navigating Tradingview","Psychological Preparation"],
        ["Reading Candlestick","Using EMA as Indicator","Market Structure","Setting Stop Loss and Take Profit","CIRCLE Strategy"]
    ])

    let [videoLinks] = useState(
        [
            ["KZSvoLABSjg","bRozeEjMhZg","6yBedrXKNCY","YZdtWWYrKYY"],
            ["oRtC1PwqH-4","DOxJ_F7dvRU","qo3KBi2W728","alq4sD7HsWc","BB1wD7I502M"],
            ["yZSGXRjGHiU","DF1gnTpJW74","SEsA_GJpfME","hT4TX9FfXvw","vMoDcr7YOwY"]
        ]
    );

    let [videoLink,setVideoLink] = useState(videoLinks[0][0]);

    let [student,setStudent] = useState(null);
    let [uid,setUID] = useState(null)
    let [ticketLevel,setTicketLevel] = useState("GOOD CHOICE")
    
    var [dates,setDates] = useState([])
    var [videoIDs,setVideoIDs] = useState({})


    useEffect(()=>{

        onAuthStateChanged(studentAuth,(student)=>{
        
          if(student){
            
            onValue(ref(studentDB,"students/"+student.uid),(snapshot)=>{
              if(snapshot.exists()){
                setUID(student.uid)
                setStudent(snapshot.val());
                setProcessing(false);
              }
            })
            
      
          }else setProcessing(false);
      
        })
      
        
    },[])


    useEffect(()=>{

        
        setVideoLink(videoLinks[selectedDay][selectedVideo])
        setProcessing(true)
        setTimeout(()=>{
            setProcessing(false);
        },1000)

    },[selectedVideo,videoLinks,selectedDay])

    useEffect(()=>{
        
        setSelectedVideo(0)
        setProcessing(true)
        setTimeout(()=>{
            setProcessing(false);
        },1000)

    },[selectedDay,videoLinks])

    useEffect(()=>{

        if(student){
            setTicketLevel(student.lowTicketLevel)
        }
    },[student])

    useEffect(()=>{

        if(dates.length > 0){
            
            onValue(ref(adminDB,`/meetingReplays/Basic Forex Training`),(snapshot)=>{

                if(snapshot.exists()){

                    var replays = snapshot.val()
                    var sb = '{'

                    for(var i = 0; i < dates.length;i++){
                        if(replays[dates[i]] != null){
                            sb += `"${dates[i]}":"${replays[dates[i]]}",`
                        }
                    }

                    sb = sb.substring(0,sb.length-1)
                    sb += '}'
                    setVideoIDs(JSON.parse(sb))

                }else setVideoIDs({})
                
            })
        }

    },[dates])

    useEffect(()=>{

        var days = []
        var currentDay = moment().startOf('week')

            
        if(moment().day() < 1)
            currentDay.subtract(1,'week')

        currentDay.add(1,'day')
        days.push(currentDay.format('MMMM DD, YYYY'))

        for(let i = 0; i < 9;i++)
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'))
        

        setDates(days)

    },[])

    function whatsNext(){
        window.location.href = "/day-4";
    }

    return(
        <>
            <Helmet>
                <title>Trading Circle - Academy</title>
            </Helmet>

            <div className='container-fluid pt-3'>

                <Header title="Three-Day Forex Power Course"/>

                <div className='row justify-content-center mt-4 mb-5'>
                    <div className='col-lg-12 col-12'>
                        <div className='row justify-content-center' style={{columnGap:'12px'}}>

                            <button onClick={()=>setSelectedDay(0)} className={`py-2 ${selectedDay === 0?"col-3 btn-blue":"col-2 btn-outline-blue"}`}><i className='fa fa-user'/> D1</button>

                            <button onClick={()=>setSelectedDay(1)} className={`py-2 ${selectedDay === 1?"col-3 btn-blue":"col-2 btn-outline-blue"}`}><i className='fa fa-compass'/> D2</button>

                            <button onClick={()=>setSelectedDay(2)} className={`py-2 ${selectedDay === 2?"col-3 btn-blue":"col-2 btn-outline-blue"}`}><i className='fa fa-chart-bar'/> D3</button>
                            
                            <button onClick={()=>whatsNext()} className={`py-2 ${selectedDay === 3?"col-3 btn-blue":"col-2 btn-outline-blue"}`}><i className='fa fa-user'/> D4</button>

                        </div>
                    </div>
                    <div className='col-lg-10 mt-5'>
                        <div className='row'>
                            <div className='col-lg-5'>
                                <h5 className='bold text-center'>{dayTitle[selectedDay]}</h5>
                                <hr className='hr-white'/>
                                <div className='row px-3 mb-4' style={{rowGap:'10px'}}>
                                {
                                    videoTitles[selectedDay].map((e,i)=>{
                                        
                                        
                                        return(
                                        <div className={`col-12 text-start pointer ${selectedVideo === i?"text-light-blue":"text-dark-blue"}`} onClick={()=>setSelectedVideo(i)}>
                                            <i className='fa fa-circle-play'/> {e}
                                        </div>
                                        )
                                    })
                                }
                                </div>
                            </div>
                            <div className='col-lg-7'>
                                <div className='video-container' style={{padding:"56.25% 0 0 0",position:"relative"}}>
                                    <iframe allowFullScreen frameborder="0"  sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation"  style={{position:"absolute",top:"0",left:"0",width:"100%",height:"100%"}} src={`https://www.youtube.com/embed/${videoLink}`} title="YouTube video player" allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className='col-12'>

                        {ticketLevel !== 'GOOD CHOICE' && 
                        <div className='row justify-content-center'>
                            
                            <div className='col-lg-10 mt-5'>
                                <h2 className='saira text-blue text-center bold'><i className='fa fa-file'/> One-Month Compounding Plan</h2>
                                <hr className='blue-hr'/>
                            </div>

                            <div className='col-lg-4 col-11 mt-5' style={{maxWidth:"375px"}} data-aos="fade-up">
                                
                                <div className='row justify-content-center'>

                                    <div className='col-12 card-container-debossed px-5 py-4 text-center' style={{minHeight:"511px"}}>
                                        <img className='pb-3' src={compoundingPlan} width="50%" alt='icon'/>
                                        <h4 className='saira text-blue text-center bold'>One-Month Compounding Plan</h4>
                                        <p className='text-blue text-justify mb-4 mt-3'>Build your concrete plan for your initial capital. This template will help you define your target pips and profit in one month. Knowing your target profit every trade is crucial for your growth as a trader.</p>
                                    </div> 
                                
                                    <div className='col-10' style={{marginTop:"-25px"}}>
                                        <a target="_blank" rel="noreferrer" href='https://firebasestorage.googleapis.com/v0/b/tradingcircle-ph.appspot.com/o/career%2FOne-Month%20Compounding%20Plan.xlsx?alt=media&token=3a830478-8fab-4d70-a47a-6ecb78e69109'><button className='form-control btn-blue py-3'><span><i className='fa fa-download ms-1'/> Download</span></button></a>
                                    </div>

                                </div>

                            </div>
                        </div>
                        }
                        
                        {ticketLevel === 'BEST CHOICE' && 
                        <div className="row justify-content-center mx-2" style={{gap:'20px'}}>

                            <div className='col-lg-10 mt-5'>
                                <h2 className='saira text-blue text-center bold'><i className='fa fa-users'/> Building Empire Strategy</h2>
                                <hr className='blue-hr'/>
                            </div>

                            {student && dates.length > 0 &&
                            <>
                                {dates.map((date)=>{

                                    return(
                                    <div className="col-lg-4 card-container px-3 py-4" key={date}>
                                        {videoIDs[date]?
                                        <div className="video-container">
                                            <iframe className='d-lg-block' id="ytplayer" type="text/html" width="100%"
                                            src={`https://www.youtube.com/embed/${videoIDs[date]}?modestbranding=1`}
                                            allowFullScreen title='hatdog' sandbox="allow-scripts allow-forms allow-same-origin allow-presentation"/>
                                        </div>
                                        :
                                        <div className="video-container">
                                            <p className="text-center text-muted saira bold" style={{fontSize:'300%'}}>N/A</p>
                                            <iframe className='d-lg-block' id="ytplayer" type="text/html" width="100%"
                                            src={``} allowFullScreen title='hatdog' sandbox="allow-scripts allow-forms allow-same-origin allow-presentation"/>
                                        </div>
                                        }
                                        <p className="text-center saira bold text-blue mt-3">{date}</p>
                                    
                                    </div>
                                    )
                                })}
                            </>
                            }

                            <div className='col-lg-10'>
                                <hr className='blue-hr'/>
                            </div>

                        </div>
                        }

                    </div>
                    

                </div>

            </div>

            <Footer/>
            <ModalLoading show={processing}/>


        </>
    )

}