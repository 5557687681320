export const globalMigrationMode = false

export const globalCoaches = [
    "alexbalingit11@gmail.com",
    "ernestivanesguerra@gmail.com",
    "karl.ocampo25@gmail.com",
    "edmarfxmail@gmail.com",
    "lukeanakinlangomez@gmail.com"
]

export const globalDevelopers = [
    "serendpity0728@gmail.com"
]

export const coachBirthday = false;

export const globalPaymentMethod = ["CEBL","MBTC","MBXB","MLH","LBPA","PSB","AAA","BITC","CBCB","CBXB","ECPY","EWXB","GCSB","LBXB","PLWN","PNBB","PNXB","PRHB","PYMB","RCDD","RCXB","SBC","SBCB","UBXB","USSC","VLRC","XNPY"]
export const globalDirectMethod = ["BITC","RCDD"]

export const globalRegions = ["Metro Manila","Mindanao","North Luzon","South Luzon","Visayas"]
export const globalProvinces = 
{
    "Metro Manila": [
        "Metro Manila"
    ],
    "Mindanao": [
        "Agusan Del Norte",
        "Agusan Del Sur",
        "Basilan",
        "Bukidnon",
        "Camiguin",
        "Compostela Valley",
        "Cotabato",
        "Davao Del Norte",
        "Davao Del Sur",
        "Davao Oriental",
        "Dinagat Islands",
        "Lanao Del Norte",
        "Lanao Del Sur",
        "Maguindanao",
        "Misamis Occidental",
        "Misamis Oriental",
        "North Cotabato",
        "Sarangani",
        "South Cotabato",
        "Sultan Kudarat",
        "Sulu",
        "Surigao Del Norte",
        "Surigao Del Sur",
        "Tawi-Tawi",
        "Zamboanga Del Norte",
        "Zamboanga Del Sur",
        "Zamboanga Sibugay"
    ],
    "North Luzon": [
        "Abra",
        "Apayao",
        "Aurora",
        "Bataan",
        "Batanes",
        "Benguet",
        "Bulacan",
        "Cagayan",
        "Ifugao",
        "Ilocos Norte",
        "Ilocos Sur",
        "Isabela",
        "Kalinga",
        "La Union",
        "Mountain Province",
        "Nueva Ecija",
        "Nueva Vizcaya",
        "Pampanga",
        "Pangasinan",
        "Quirino",
        "Tarlac",
        "Zambales"
    ],
    "South Luzon": [
        "Albay",
        "Batangas",
        "Camarines Norte",
        "Camarines Sur",
        "Catanduanes",
        "Cavite",
        "Laguna",
        "Marinduque",
        "Masbate",
        "Occidental Mindoro",
        "Oriental Mindoro",
        "Palawan",
        "Quezon",
        "Rizal",
        "Romblon",
        "Sorsogon"
    ],
    "Visayas": [
        "Aklan",
        "Antique",
        "Biliran",
        "Bohol",
        "Capiz",
        "Cebu",
        "Eastern Samar",
        "Guimaras",
        "Iloilo",
        "Leyte",
        "Negros Occidental",
        "Negros Oriental",
        "Northern Samar",
        "Siquijor",
        "Southern Leyte",
        "Western Samar"
    ]
}

export const reactEmoji = {
    'Like':'👍',
    'Love':'❤️',
    'Haha':'😆',
    'Wow':'😯',
    'Sad':'😢',
    'Angry':'😠'
}

export const reactColor = {
    'Like':'#FFB02E',
    'Love':'#CB0B48',
    'Haha':'#FFB02E',
    'Wow':'#FFB02E',
    'Sad':'#FFB02E',
    'Angry':'#FFB02E'
}

export const allowedImages = [
    'PNG',
    'JPG',
    'JPEG',
    'WEBP',
    'GIF'
]

export const allowedVideos = [
    'MOV',
    'AVI',
    'MP4',  
    'MKV',
    'FLV',
    'WMV',
    'MPG',
    'MPEG'
]

export const allowedAttachments = [
    'PDF',
    'XLSX',
    'CSV',
    'DOCX',
    'DOC',
    'XLS'
]