/* eslint-disable react-hooks/exhaustive-deps */

import { Helmet } from 'react-helmet-async';

import ModalLoading from '../../components/general/ModalLoading';
import { useEffect, useState } from 'react';

import {Header} from '../../components/general/Header'
import { onValue, ref, set,push } from 'firebase/database';
import { onAuthStateChanged } from 'firebase/auth';
import { studentAuth, studentDB } from '../../api/firebase-student-config';
import { Footer } from '../../components/academy/Footer';

import googleMeetLogo from '../../img/logo/google-meet-logo.png';

import moment from 'moment'
import $ from 'jquery'
import ModalConfirmation from '../../components/general/ModalConfirmation';
import { adminDB } from '../../api/firebase-admin-config';

export const Training = () =>{
    
    const [processing,setProcessing] = useState(false);

    var [uid,setUID] = useState(null);
    var [student,setStudent] = useState(null);
    var [innerCircle,setInnerCircle] = useState(false)

    var [selected,setSelected] = useState('group-training')

    var [tradingStatus,setTradingStatus] = useState("New trader")
    var [tradingExperience,setTradingExperience] = useState("1 - 3 Months")
    
    var [preferredTopics,setPreferredTopics] = useState()
    var [preferredTime,setPreferredTime] = useState()

    var [bookInfo,setBookInfo] = useState()
    var [booking,setBooking] = useState(false)
    var [cancellingBooking,setCancellingBooking] = useState(false)
    var [finishingBooking,setFinishingBooking] = useState(false)
    
    var [timeRemaining, setTimeRemaining] = useState()
    var [hourRemaining, setHourRemaining] = useState()

    var [histories,setHistories] = useState([])
    var [slotRemaining,setSlotRemaining] = useState(0)

    var [meetingIndex,setMeetingIndex] = useState(0)



    var [meetings] = useState([
        {
            time:'7:30 PM',
            day:'Mondays',
            topic:'Basic Forex Training',
            level:'Standard',
            link:'https://meet.google.com/xtd-kudu-jja'
        },
        {
            time:'7:30 PM',
            day:'Saturdays',
            topic:'Weekend Planning',
            level:'Elite',
            link:'https://meet.google.com/ums-aeeo-xbe'
        }
    ])
    
    var [dates,setDates] = useState([])
    var [videoIDs,setVideoIDs] = useState({})

    useEffect(()=>{

        if(uid){
            onValue(ref(studentDB,`partnershipRequests/${uid}`),(snapshot)=>{
                if(snapshot.exists()){
                    if(snapshot.val().status === 'Approved') setInnerCircle(true)
                    else setInnerCircle(false)

                }else setInnerCircle(false)
            })
        } 

    },[uid])

    useEffect(()=>{

        var days = []
        var currentDay = moment().startOf('week')

        if(meetings[meetingIndex].day === 'Mondays'){
            
            if(moment().day() < 1)
                currentDay.subtract(1,'week')
            
            
            
            currentDay.add(1,'day')
            days.push(currentDay.format('MMMM DD, YYYY'))
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'))
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'))
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'))
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'))
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'))
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'))
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'))
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'))
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'))

        }else if(meetings[meetingIndex].day === 'Wednesdays'){

            if(moment().day() < 3)
                currentDay.subtract(1,'week')

            currentDay.add(3,'day')
            days.push(currentDay.format('MMMM DD, YYYY'))
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'))
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'))
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'))
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'))
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'))
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'))
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'))
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'))
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'))
        }else if(meetings[meetingIndex].day === 'Thursdays'){

            if(moment().day() < 4)
                currentDay.subtract(1,'week')

            currentDay.add(4,'day')
            days.push(currentDay.format('MMMM DD, YYYY'))
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'))
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'))
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'))
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'))
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'))
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'))
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'))
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'))
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'))
        }
        else if(meetings[meetingIndex].day === 'Saturdays'){

            if(moment().day() < 6)
                currentDay.subtract(1,'week')

            currentDay.add(6,'day')
            days.push(currentDay.format('MMMM DD, YYYY'))
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'))
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'))
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'));
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'));
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'));
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'));
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'));
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'));
            days.push(currentDay.subtract(1,'week').format('MMMM DD, YYYY'));
        }

        setDates(days)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[meetingIndex])

    useEffect(()=>{

        if(dates.length > 0){
            
            onValue(ref(adminDB,`/meetingReplays/${meetings[meetingIndex].topic}`),(snapshot)=>{

                if(snapshot.exists()){

                    var replays = snapshot.val()
                    var sb = '{'

                    for(var i = 0; i < dates.length;i++){
                        if(replays[dates[i]] != null){
                            sb += `"${dates[i]}":"${replays[dates[i]]}",`
                        }
                    }

                    sb = sb.substring(0,sb.length-1)
                    sb += '}'
                    setVideoIDs(JSON.parse(sb))

                }else setVideoIDs({})
                
            })
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dates])

    useEffect(()=>{

        onAuthStateChanged(studentAuth,(student)=>{
      
            if(student){
        
              onValue(ref(studentDB,"students/"+student.uid),(snapshot)=>{
                if(snapshot.exists()){
                    setUID(student.uid);
                    setStudent(snapshot.val());
                    setProcessing(false);
                }
              })
        
            }else setProcessing(false);
            
        
        })
          
        if(student){

            onValue(ref(studentDB,'trainingRequests/'),(snapshot)=>{
                if(snapshot.exists()){

                    var reqs = snapshot.val()

                    if(reqs[uid] !== undefined){
                        setBookInfo(reqs[uid])

                        //moment(reqs[uid].approvedTime).fromNow()

                    }
                    else setBookInfo(null)

                }else setBookInfo(null)
            })

            onValue(ref(studentDB,'trainingHistory/'+uid),(snapshot)=>{
                if(snapshot.exists()){

                    var count = 0
                    var hs = []

                    for(var _key in snapshot.val()){
                        var curr = snapshot.val()[_key]
                        curr['number'] = ++count
                        curr['key'] = _key
                        hs.push(curr)
                    }
                    
                    setHistories(hs)

                    if(student.wealthcircle) setSlotRemaining(999);
                    else if(innerCircle && student.level === 'Elite') setSlotRemaining(3 - count)
                    else if(innerCircle) setSlotRemaining(1 - count)
                    else if(student.level === 'Elite') setSlotRemaining(2 - count)
                    else setSlotRemaining(0)
                    
                }else{
                    
                    if(student.wealthcircle) setSlotRemaining(999);
                    else if(innerCircle && student.level === 'Elite') setSlotRemaining(3)
                    else if(innerCircle) setSlotRemaining(1)
                    else if(student.level === 'Elite') setSlotRemaining(2)
                    else setSlotRemaining(0)

                }

            })

        }
        

    },[selected])

    useEffect(()=>{

        if(bookInfo && bookInfo.approvedTime){
            const inte = setInterval(() =>{
                setTimeRemaining(moment(bookInfo.approvedTime).fromNow(true))
                setHourRemaining(moment(bookInfo.approvedTime).diff(moment(),'hours'))
            },1000)

            return () => clearInterval(inte)
        }

    },[selected,bookInfo])

    function verifyTradingStatus(){
        var slcTradingStatus = $('#slcTradingStatus').find(":selected").val();
        setTradingStatus(slcTradingStatus)
    }

    function verifyTradingExperience(){
        var slcTradingExperience = $('#slcTradingExperience').find(":selected").val();
        setTradingExperience(slcTradingExperience)
    }

    function verifyPreferredTopics(){

        var txtPreferredTopics = $('#txtPreferredTopics').val()
        var errPreferredTopics = $('#errPreferredTopics') 

        setPreferredTopics('')
        errPreferredTopics.text('')

        if(txtPreferredTopics.trim().length === 0) errPreferredTopics.text('Blank Spaces are not allowed')
        else if(txtPreferredTopics.length <= 10) errPreferredTopics.text('Atleast 10 Characters')
        else setPreferredTopics(txtPreferredTopics)
        

    }

    function verifyPreferredTime(){

        var txtPreferredTime = $('#txtPreferredTime').val()
        var errPreferredTime = $('#errPreferredTime') 

        setPreferredTime('')
        errPreferredTime.text('')

        if(txtPreferredTime.trim().length === 0) errPreferredTime.text('Blank Spaces are not allowed')
        else setPreferredTime(txtPreferredTime)

    }

    function bookTraining(){

        setProcessing(true)

        var queueTime = moment().toISOString()

        var body = {
            tradingStatus:tradingStatus,
            tradingExperience:tradingExperience,
            preferredTopics:preferredTopics,
            preferredTime:preferredTime,
            queueTime:queueTime
        }

        set(ref(studentDB,'trainingRequests/' + uid),body).then(()=>{
            setProcessing(false)
            setBooking(false)
        }).catch((error)=>{
            setProcessing(false)
            setBooking(false)
            alert(error.message)
        })

    }

    function cancelBooking(){

        set(ref(studentDB,'trainingRequests/'+uid),{}).then(()=>{

            
            verifyPreferredTime()
            verifyPreferredTopics()
            $('#errPreferredTime').text('')
            $('#errPreferredTopics').text('')

            verifyTradingExperience()
            verifyTradingStatus()

            setProcessing(false)
            setCancellingBooking(false)
        }).catch((error)=>{
            setProcessing(false)
            setCancellingBooking(false)
            alert(error.message)
        })

    }

    function finishBooking(){

        setProcessing(true)

        push(ref(studentDB,'trainingHistory/'+uid),bookInfo).then((_result)=>{

            set(ref(studentDB,'trainingRequests/'+uid),{}).then(()=>{

                setProcessing(false)
                setFinishingBooking(false)

            }).catch((error)=>{
                setProcessing(false)
                setFinishingBooking(false)
                alert(error.message)
            })

        }).catch((error)=>{
            setProcessing(false)
            setFinishingBooking(false)
            alert(error.message)
        })

    }

    function prevPage(){
        setMeetingIndex(meetingIndex === 0?meetings.length-1:meetingIndex-1)
    }

    function nextPage(){
        setMeetingIndex(meetingIndex === meetings.length-1?0:meetingIndex+1)
    }

    return(
        <>
            <Helmet>
                <title>Trading Circle - Training</title>
            </Helmet>
            
            <div className="container-fluid animate__animated animate__fadeIn p-4">

                <Header title='Training'/>

                {/* START: Training Navbar Desktop */}
                <div className='row justify-content-center mb-5 mt-3 d-lg-flex d-none' style={{gap:"40px"}}>
                    <div onClick={()=>setSelected('group-training')} className='col-lg-4 text-center pointer smooth-transition' style={{borderBottom:selected === 'group-training'?"3px solid var(--primary)":"3px solid rgba(0,0,0,0.2)"}}>
                        <h5 className={selected === 'group-training'?"bold text-blue smooth-transition":"bold smooth-transition"}><i className='fa fa-users'/> Group Training</h5>
                    </div>
                    {student && (student.level ==='Elite' || innerCircle) ?
                    <div onClick={()=>setSelected('1-on-1-training')} className='col-lg-4 text-center pointer smooth-transition' style={{borderBottom:selected === '1-on-1-training'?"3px solid var(--primary)":"3px solid rgba(0,0,0,0.2)"}}>
                        <h5 className={selected === '1-on-1-training'?"bold text-blue smooth-transition":"bold smooth-transition"}><i className="fa-solid fa-handshake"/> 1-on-1 Training</h5>
                    </div>
                    :
                    <div className='col-lg-4 text-center smooth-transition' style={{borderBottom:"3px solid rgba(0,0,0,0.2)",opacity:"0.7"}}>
                        <h5 className='bold'><i className='fa fa-lock'/> 1-on-1 Training</h5>
                    </div>
                    }
                </div>
                {/* END: Training Navbar Desktop */}

                {/* START: Training Navbar Mobile */}
                <div className='row justify-content-center mb-5 mt-3 d-lg-none d-flex' style={{gap:"10px"}}>
                    <div onClick={()=>setSelected('group-training')} className='col-5 text-center pointer smooth-transition' style={{borderBottom:selected === 'group-training'?"3px solid var(--primary)":"3px solid rgba(0,0,0,0.2)"}}>
                        <h6 className={selected === 'group-training'?"bold text-blue smooth-transition":"bold smooth-transition"}><i className='fa fa-users'/> Group</h6>
                    </div>
                    {student && (student.level ==='Elite' || innerCircle)?
                    <div onClick={()=>setSelected('1-on-1-training')} className='col-5 text-center pointer smooth-transition' style={{borderBottom:selected === '1-on-1-training'?"3px solid var(--primary)":"3px solid rgba(0,0,0,0.2)"}}>
                        <h6 className={selected === '1-on-1-training'?"bold text-blue smooth-transition":"bold smooth-transition"}><i className="fa-solid fa-handshake"/> 1-on-1</h6>
                    </div>
                    :
                    <div className='col-5 text-center smooth-transition' style={{borderBottom:"3px solid rgba(0,0,0,0.2)",opacity:"0.7"}}>
                        <h6 className='bold'><i className='fa fa-lock'/> 1-on-1</h6>
                    </div>
                    }
                </div>
                {/* END: Training Navbar Mobile */}

                {selected === 'group-training' &&
                <div className='row justify-content-center animate__animated animate__fadeIn mb-4'>
                    
                    <div className='col-lg-8'>

                        <div className='row justify-content-center d-flex' style={{gap:"10px 40px"}}>
                            <div className='col-lg-5 col-11 pt-5 pb-4 card-container group-training-container mb-5'>
                                <div className='row h-100'>

                                    <div className='col-12 mb-4'>
                                        <div className='row justify-content-center'>
                                            <div className='col-12 d-flex justify-content-center' style={{gap:"10px"}}>
                                                <div className={meetingIndex===0?'dot active':'dot'}/>
                                                <div className={meetingIndex===1?'dot active':'dot'}/>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className='col-12 align-self-center' style={{position:'relative'}}>

                                        <p className='text-center saira text-blue' style={{fontSize:"20px"}}><b>Topic: </b>{meetings[meetingIndex].topic}</p>
                                        <p className='text-center saira text-blue' style={{fontSize:"20px"}}><b>Level: </b>
                                            <span>
                                                {meetings[meetingIndex].level === 'Standard'? "Standard - Elite":meetings[meetingIndex].level}
                                            </span>
                                        </p>
                                        <h1 className='text-center black pt-5 text-blue m-0'>{meetings[meetingIndex].time}</h1>
                                        <h3 className='text-center saira bold text-blue m-0'>{meetings[meetingIndex].day}</h3>
                                        <p className='mt-5 px-4 text-justify text-muted' style={{fontSize:"14px"}}>*Use a decent profile picture and use your full name on your Google Meet Account. You will be rejected if you do not follow this rule.</p>
                                        
                                        {meetings[meetingIndex].level !== 'Inner Circle'?
                                            student && (student.level === 'Elite' || student.level === meetings[meetingIndex].level)?
                                            <a href={meetings[meetingIndex].link} rel='noreferrer' target="_blank"><button className='form-control btn-blue mt-4 py-2 smooth-transition'>Join "{meetings[meetingIndex].topic}"</button></a>
                                            :
                                            <a href="/"><button className='form-control btn-blue mt-4 py-2 smooth-transition' disabled><i className='fa fa-lock me-2'/>Elite Only</button></a>
                                        :
                                            innerCircle?
                                            <a href={meetings[meetingIndex].link} rel='noreferrer' target="_blank"><button className='form-control btn-blue mt-4 py-2 smooth-transition'>Join "{meetings[meetingIndex].topic}"</button></a>
                                            :
                                            <a href="/"><button className='form-control btn-blue mt-4 py-2 smooth-transition' disabled><i className='fa fa-lock me-2'/>Inner Circle Only</button></a>
                                        }

                                        <i className='fa fa-caret-right text-blue pointer' onClick={()=>nextPage()} style={{fontSize:'48px',position:'absolute',top:'30%',left:'85%'}}/>
                                        <i className='fa fa-caret-left text-blue pointer' onClick={()=>prevPage()} style={{fontSize:'48px',position:'absolute',top:'30%',right:'85%'}}/>

                                    </div>
                                    
                                </div>
                            </div>

                            <div className='col-lg-5 col-11 text-center align-self-center mt-lg-0 mt-4' style={{maxWidth:'585px'}}>
                                <img src={googleMeetLogo} alt='Google Meet Icon' width="50%"/>
                                <p className='mt-3 px-lg-4 px-0' style={{fontSize:"20px"}}>Our training will be conducted via Google Meet App.</p>
                            </div>

                        </div>
                    </div>

                    <div className='col-11'>
                        
                        <div className="row justify-content-center" style={{gap:'20px'}}>

                            <div className='col-lg-10 mt-5'>
                                <h2 className='saira text-blue text-center bold'>Replays</h2>
                                <hr className='blue-hr'/>
                            </div>

                            {student && dates.length > 0? (meetingIndex !== 2 && student.level === 'Elite') || (student.level === 'Standard' && meetingIndex === 0) || (innerCircle && meetingIndex === 2)?
                            <>
                                {dates.map((date)=>{

                                    return(
                                    <div className="col-lg-4 card-container px-3 py-4" key={date}>
                                        {videoIDs[date]?
                                        <div className="video-container">
                                            <iframe className='d-lg-block' id="ytplayer" type="text/html" width="100%"
                                            src={`https://www.youtube.com/embed/${videoIDs[date]}?modestbranding=1`}
                                            allowFullScreen title='hatdog' sandbox="allow-scripts allow-forms allow-same-origin allow-presentation"/>
                                        </div>
                                        :
                                        <div className="video-container">
                                            <p className="text-center text-muted saira bold" style={{fontSize:'300%'}}>N/A</p>
                                            <iframe className='d-lg-block' id="ytplayer" type="text/html" width="100%"
                                            src={``} allowFullScreen title='hatdog' sandbox="allow-scripts allow-forms allow-same-origin allow-presentation"/>
                                        </div>
                                        }
                                        <p className="text-center saira bold text-blue mt-3">{date}</p>
                                    
                                    </div>
                                    )
                                })}
                            </>
                            :
                            <div className='col-lg-10'>
                                <h4 className='text-blue bold saira text-center' style={{opacity:'0.3'}}><i className='fa fa-lock me-1'/> Replays for {meetings[meetingIndex].topic} are for {meetingIndex === 2?"Inner Circle only":"Elite only"}</h4>
                            </div>
                            :
                            <></>
                            }

                            <div className='col-lg-10'>
                                <hr className='blue-hr'/>
                            </div>

                        </div>

                    </div>

                </div>
                }

                {selected === '1-on-1-training' &&
                <div className='row justify-content-center mb-5 mt-3 animate__animated animate__fadeIn'>
                    

                    <div className='col-lg-8'>

                        <div className='row justify-content-center' style={{gap:"20px"}}>

                            {/*START: Book 1 on 1 TRAINING*/}
                            {!bookInfo &&
                            <div className='col-lg-7 col-12 card-container booking-container py-4 px-lg-5 px-4'>
                            
                                <h3 className='text-center saira bold text-blue'><i className="fa-solid fa-handshake"/> Book your 1-on-1 Training</h3>
                                
                                {student && student.wealthcircle?
                                <p className='text-center mb-4 bold text-light-blue'><i className='fa fa-infinity'/> UNLIMITED Training/s Left</p>
                                :
                                slotRemaining > 0 && <p className='text-center mb-4 bold text-light-blue'>{slotRemaining} Training/s Left</p>
                                }

                                {(slotRemaining > 0 || (student && student.wealthcircle)) &&
                                <>
                                <label className='text-blue bold'>Trading Status</label>
                                <select onChange={()=>verifyTradingStatus()} id="slcTradingStatus" className='form-select'>
                                    <option value="New trader">New trader</option>
                                    <option value="Trader with unsatisfied results">Trader with unsatisfied results</option>
                                    <option value="Trader who wants to improve skills">Trader who wants to improve skills</option>
                                </select>
                                <small id="errTradingStatus" className="form-text text-danger d-block"></small>

                                <label className='text-blue bold mt-3'>Trading Experience</label>
                                <select onChange={()=>verifyTradingExperience()} id="slcTradingExperience" className='form-select'>
                                    <option value="1 - 3 Months">1 - 3 Months</option>
                                    <option value="4 - 6 Months">4 - 6 Months</option>
                                    <option value="6 Months - 1 Year">6 Months - 1 Year</option>
                                    <option value="2 Years and above">2 Years and above</option>
                                </select>
                                <small id="errTradingExperience" className="form-text text-danger d-block"></small>

                                <label className='text-blue bold mt-3'>Preferred Topics</label>
                                <textarea onChange={()=>verifyPreferredTopics()} id="txtPreferredTopics" rows={4} className='form-control' style={{resize:'none'}} placeholder='Topics You want to Cover'/>
                                <small id="errPreferredTopics" className="form-text text-danger d-block"></small>

                                <label className='text-blue bold mt-3'>Preferred Time</label>
                                <input onChange={()=>verifyPreferredTime()} id='txtPreferredTime' className="form-control" type='datetime-local' min={moment().add(1,'day').format('YYYY-MM-DDThh:mm')}/>
                                <small id="errPreferredTime" className="form-text text-danger d-block"></small>
                                <hr/>

                                {tradingExperience && tradingStatus && preferredTopics && preferredTime?
                                <button className='form-control py-2' onClick={()=>setBooking(true)}>Book Training</button>
                                :
                                <button className='form-control py-2' disabled>Book Training</button>
                                }
                                </>
                                }

                                {slotRemaining === 0 && student && student.wealthcircle &&
                                <div className='col-12' style={{minHeight:"150px"}}>
                                    <div className='row' style={{minHeight:"150px"}}>
                                        <div className='col-12 align-self-center'>
                                            <p className='text-center' style={{fontSize:"18px"}}>You have used all of your 1-on-1 training slots.</p>
                                            <p className='saira bold text-error background-faded-error px-2 text-center py-3 mt-4' style={{borderRadius:"25px",position:'relative',width:"120%",right:"10%"}}>Note: You have no slot for 1-on-1 training.</p>
                                        </div>
                                    </div>
                                </div>
                                }
                            
                            </div>
                            }
                            {/*END: Book 1 on 1 TRAINING*/}

                            {/*START: Already Booked TRAINING*/}
                            {bookInfo &&
                            <div className='col-lg-7 col-12 card-container py-4 px-lg-5 px-4'>
                            
                                <h3 className='text-center saira bold text-blue'><i className="fa-solid fa-check"/> Already Booked</h3>
                                {bookInfo && bookInfo.approvedTime?
                                moment().isAfter(bookInfo.approvedTime) && hourRemaining === 0?
                                <p className='text-center mb-4 bold text-light-blue'>ONGOING</p>
                                :
                                hourRemaining <= -1?
                                <p className='text-center mb-4 bold text-error'>ENDED</p>
                                :
                                <p className='text-center mb-4 bold text-success'>APPROVED</p>
                                :
                                <p className='text-center mb-4 bold text-pending'>PENDING</p>
                                }

                                <label className='text-blue bold'>Trading Status</label>
                                <select disabled value={bookInfo.tradingStatus} className='form-select'>
                                    <option value="New trader">New trader</option>
                                    <option value="Trader with unsatisfied results">Trader with unsatisfied results</option>
                                    <option value="Trader who wants to improve skills">Trader who wants to improve skills</option>
                                </select>

                                <label className='text-blue bold mt-3'>Trading Experience</label>
                                <select disabled value={bookInfo.tradingExperience} className='form-select'>
                                    <option value="1 - 3 Months">1 - 3 Months</option>
                                    <option value="4 - 6 Months">4 - 6 Months</option>
                                    <option value="6 Months - 1 Year">6 Months - 1 Year</option>
                                    <option value="2 Years and above">2 Years and above</option>
                                </select>

                                <label className='text-blue bold mt-3'>Preferred Topics</label>
                                <textarea disabled rows={4} className='form-control' style={{resize:'none'}} value={bookInfo.preferredTopics}/>

                                {bookInfo && bookInfo.approvedTime?
                                <>
                                    <label className='text-success bold mt-3'><i className='fa fa-check'/> Approved Time</label>
                                    <p className='bold saira text-blue'>{moment(bookInfo.approvedTime).format('MMMM DD, YYYY hh:mm A')}</p>
                                    
                                    <label className='text-success bold mt-2'><i className='fa fa-user'/> Assigned Coach</label>
                                    <p className='bold saira text-blue'>{bookInfo.coach}</p>

                                    {moment().isAfter(bookInfo.approvedTime)?
                                    hourRemaining <= -1?
                                    <p className='text-muted' style={{fontSize:"14px"}}>Meeting already ended.</p>
                                    :
                                    <p className='text-muted' style={{fontSize:"14px"}}>Meeting is ongoing {timeRemaining} ago.</p>
                                    :
                                    <p className='text-muted' style={{fontSize:"14px"}}>Meeting is in {timeRemaining}.</p>
                                    }
                                </>
                                :
                                <>
                                    <label className='text-blue bold mt-3'>Preferred Time</label>
                                    <input disabled value={bookInfo.preferredTime} className="form-control" type='datetime-local'/>
                                </>
                                }

                                <hr/>
                                
                                {bookInfo && !bookInfo.approvedTime?
                                <button className='form-control py-2 btn-outline' onClick={()=>setCancellingBooking(true)}>Cancel Training</button>
                                :
                                moment().isAfter(bookInfo.approvedTime) && hourRemaining <= -1?
                                <>
                                    <button className='form-control py-2 btn-outline' onClick={()=>setFinishingBooking(true)}>Finish Training</button>
                                    <p className='text-muted mt-3' style={{fontSize:"14px"}}>*Training Ended, click the button to confirm.</p>
                                    <p className='text-muted' style={{fontSize:"14px"}}>*Failure to attend the training is still counted as a slot.</p>
                                </>
                                :
                                moment(bookInfo.approvedTime).diff(moment(),'minutes') <= 10?
                                <>
                                    <a href={bookInfo.meetLink || "https://meet.google.com/wky-havg-hej"} rel='noreferrer' target="_blank"><button className='form-control py-2 btn-blue'>Join Training</button></a>
                                    <p className='text-muted mt-3' style={{fontSize:"14px"}}>*You can join the meeting <b>10 minutes</b> before the approved time.</p>
                                    <p className='text-justify text-muted mt-1' style={{fontSize:"14px"}}>*Use a decent profile picture and use your full name on your Google Meet Account so the coaches can distinguish you.</p>
                                    <p className='text-muted mt-1' style={{fontSize:"14px"}}>*Failure to attend the training is still counted as a slot.</p>
                                </>
                                :
                                <>
                                    <button className='form-control py-2 btn-blue' disabled>Join Training</button>
                                    <p className='text-muted mt-3' style={{fontSize:"14px"}}>*You can join the meeting <b>10 minutes</b> before the approved time.</p>
                                    <p className='text-justify text-muted mt-1' style={{fontSize:"14px"}}>*Use a decent profile picture and use your full name on your Google Meet Account so the coaches can distinguish you.</p>
                                    <p className='text-muted mt-1' style={{fontSize:"14px"}}>*Failure to attend the training is still counted as a slot.</p>
                                </>
                                }
                            </div>

                            }
                            {/*END: Already Booked TRAINING*/}

                            <div className='col-lg-3 card-container-debossed py-4' style={{minWidth:"315px"}}>
                                <p className='text-center saira bold text-blue'>Training History</p>
                                <div className='row h-100'>

                                    <div className={histories.length > 0?'col-12 text-center pt-4 pb-5':'col-12 align-self-center text-center pt-4 pb-5'}>
                                        {histories.length > 0?
                                        histories.map((history)=>{
                                            return(
                                                <div className='card-container mx-3 py-3 px-2 my-3 text-blue'>

                                                    <h5 className='bold saira m-0'>Training #{history.number}</h5>
                                                    <hr className='my-2 blue-hr' style={{opacity:"0.5"}}/>

                                                    <p>{history.key}</p>
                                                    <p className='bold mt-3'>Date</p>
                                                    <p>{moment(history.approvedTime).format('MMMM DD, YYYY')}</p>

                                                    <p className='bold mt-3'>Time</p>
                                                    <p>{moment(history.approvedTime).format('hh:mm A')}</p>
                                                </div>
                                            )
                                        })
                                        :
                                        <p className='text-muted bold'>No History Yet</p>
                                        }
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>

                </div>
                }

            </div>
            
            <Footer/>

            <ModalConfirmation show={booking} onHide={()=>setBooking(false)} onConfirm={()=>bookTraining()} title="Book Training?" message="To avoid any inconveniences, make sure you have filled out the booking form properly."/>
            <ModalConfirmation show={cancellingBooking} onHide={()=>setCancellingBooking(false)} onConfirm={()=>cancelBooking()} title="Cancel Training?" message="Are you sure you want to cancel your training, you will be pulled back in the queue."/>
            <ModalConfirmation show={finishingBooking} onHide={()=>setFinishingBooking(false)} onConfirm={()=>finishBooking()} title="Finish Training?" message="You can book another training if you still have a slot left."/>
            <ModalLoading show={processing}/>
        </>
    );

}