import { Helmet } from 'react-helmet-async';


import offer3 from '../../img/tripwire/offer-1.png'
import offer2 from '../../img/tripwire/offer-2.png'
import offer1 from '../../img/tripwire/offer-3.png'

import bdo from '../../img/logo/bdo.png'
import chinaBank from '../../img/logo/china-bank.png'
import coinsPH from '../../img/logo/coins-ph.png'
import gcash from '../../img/logo/gcash.png'
import landBank from '../../img/logo/landbank.png'
import maya from '../../img/logo/maya.png'
import bpi from '../../img/logo/bpi.png'
import securityBank from '../../img/logo/security-bank.png'
import unionBank from '../../img/logo/union-bank.png'

import profilePlaceholder from '../../img/placeholder/profile-placeholder.png';
import imagePlaceholder from '../../img/placeholder/image-placeholder.png';

import moment from 'moment'
import { useEffect, useState } from 'react';
import blackWordLogo from '../../img/logo/black-word-logo.png';
import { FunnelFooter } from '../../components/landing/FunnelFooter';

import {ref, onValue, update} from 'firebase/database'
import $ from 'jquery'

import irregShape from "../../img/bg/irregular-bg.png"
import { onAuthStateChanged } from 'firebase/auth';
import { studentAuth, studentDB, studentStorage } from '../../api/firebase-student-config';
import { useSearchParams } from 'react-router-dom';
import { getFileExt } from '../../helper/formatter';
import {getDownloadURL, ref as sref, uploadBytes} from 'firebase/storage'
import ModalLoading from '../../components/general/ModalLoading';

export const ThreeDayMasterClassCheckout = (props) =>{

    let [processing,setProcessing] = useState(false)

    let [student,setStudent] = useState();
    let [studentInfo,setStudentInfo] = useState();
    let [searchParams] = useSearchParams();
    
    let [offer,setOffer] = useState("1");

    const [redirectURL] = useState('/three-day-masterclass?type=register')

    const [proofURL,setProofURL] = useState(imagePlaceholder)
    const [status,setStatus] = useState(`<span class='text-danger'>Unpaid</span>`)
    const [reason,setReason] = useState()

    const [canSubmit,setCanSubmit] = useState(true)

    useEffect(()=>{

        onAuthStateChanged(studentAuth,(s)=>{
            
            if(s) setStudent(s)
            else{
                setStudent(null)
                setStudentInfo(null)
            }

        },[])

    },[]);

    useEffect(()=>{

        if(!student) return;

        onValue(ref(studentDB,`students/${student.uid}`),(snapshot)=>{
            if(snapshot.exists()){
                setStudentInfo(snapshot.val());
                console.log(snapshot.val())
            }
        });

    },[student])

    useEffect(()=>{

        if(!studentInfo) return;

        if(studentInfo.level) window.location.href = "/";

        let p = studentInfo.manualPayment;

        if(!p) return;
        if(!p.lowTicket) return;

        if(p.lowTicket.status === 'Pending') setStatus(`<span class='text-pending'>Pending</span>`)
        else if(p.lowTicket.status === 'Rejected'){
            setStatus(`<span class='text-error'>Rejected</span>`)
            setReason(p.lowTicket.reason)
        }else if(p.lowTicket.status === 'Verified'){
            setStatus(`<span class='text-success'>Verified</span>`)
            setCanSubmit(false)
        }                            
        
        setOffer(p.lowTicket.offer)
        setProofURL(p.lowTicket.proof)

    },[studentInfo])

    useEffect(()=>{

        if(!searchParams.get("offer")) setOffer("1");
        else setOffer(searchParams.get("offer"));

    },[searchParams])

    var[hour,setHour] = useState(0)
    var[minute,setMinute] = useState(0)
    var[second,setSecond] = useState(0)

    useEffect(()=>{

        var eventTime= moment().add(1,'day').unix(); 
        var currentTime = moment(moment().month()+1 + '-' +moment().date()+ '-'+moment().year() ,'MM-DD-YY');
        var diffTime =  currentTime - eventTime;
        var duration = moment.duration(diffTime*1000, 'milliseconds');
        var interval = 1000;

        const inte = setInterval(() =>{
            duration = moment.duration(duration - interval, 'milliseconds');
            setHour(duration.hours() < 10?'0'+ String(duration.hours()):duration.hours())
            setMinute(duration.minutes() < 10?'0'+ String(duration.minutes()):duration.minutes())
            setSecond(duration.seconds() < 10?'0'+ String(duration.seconds()):duration.seconds())
        }, interval);

        return () => clearInterval(inte);

    },[])

    function openProof(){
        if(proofURL === imagePlaceholder) return
        window.open(proofURL)
    }

    function uploadProof(){

        setProcessing(true)
        
        var file = $('#fileUploadProof').prop('files')[0]
        var txtErr = $("#errProfilePicture")
        txtErr.text('')

        if(!file){
            setProcessing(false)
            return;
        }else{
            if(getFileExt(file.name).toLowerCase() !== 'png' && getFileExt(file.name).toLowerCase() !== 'jpg' && getFileExt(file.name).toLowerCase() !== 'jpeg'){
                
                txtErr.text('PNG and JPG Files are the only accepted files.')
                $('#fileUploadProof').val(null)
                setProcessing(false)
                return
            }
        }

        setStatus("<span class='text-danger'>Unpaid</span>")

        uploadBytes(sref(studentStorage,`students/paymentProof/${student.uid}`),file).then(()=>{

            getDownloadURL(sref(studentStorage,`students/paymentProof/${student.uid}`)).then((url)=>{
                
                setProofURL(url)

                var type = `lowTicket`
                var body = {}

                var time = moment().format()
                
                body[type] = {'status':'Pending','proof':url,'date':time,offer:offer}

                update(ref(studentDB,`students/${student.uid}/manualPayment`),body).then(()=>{

                    setProcessing(false)
                    setReason(null)

                    //sendAdminMail()

                }).catch((error)=>{
                    console.log(error)
                    setProcessing(false)
                })
                
            })

        }).catch((error)=>{
            console.log(error)
            setProcessing(false)
        })

    }

    return(
        <>
            <Helmet>
                <title>Trading Circle - Checkout</title>
                <script>
                    fbq('track', 'InitiateCheckout')
                </script>
            </Helmet>

            <div className="container-fluid animate__animated animate__fadeIn px-lg-2 px-4 py-4">
                <div className='row justify-content-center mt-lg-3 mt-0 text-center'>

                    <div className='col-12 text-center mb-4'>
                        <img className='d-none d-lg-inline darkmode-logo' src={blackWordLogo} width='20%' alt='logo'/>
                        <img className='d-inline d-lg-none darkmode-logo' src={blackWordLogo} width='70%' alt='logo'/>
                        <div className='row justify-content-center'>
                            <div className='col-lg-6 col-12'>
                                <hr className='blue-hr my-3' style={{opacity:'0.5'}}/>
                            </div>
                        </div>
                        <h3 className='black text-blue' style={{fontSize:'calc(1rem + 0.75vw)'}}>Payment</h3>
                        <div className='row justify-content-center'>
                            <div className='col-lg-7 col-11'>
                                <h4 className='bold' style={{fontSize:'calc(0.75rem + 0.5vw)'}}>Please ensure that you type the correct details, and validate these before authorizing the payments from your mobile banking apps, e-wallets, or remittances</h4>
                            </div>
                        </div>
                    </div>

                    <div className='col-12 mb-5'>
                        <div className='row justify-content-center' data-aos="fade-up">
                            <div className='col-12'>
                                <div className='row justify-content-center'>
                                    <div className='col-12 mt-2'>
                                        <p className='text-muted saira bold text-center mb-1'>EXPIRES IN</p>
                                    </div>

                                    <div className='col-lg-10 text-center'>
                                        <h1 className='black' style={{fontSize:"calc(1rem + 2vw)"}}>
                                            {hour}
                                            <span className='saira text-light-blue me-lg-5 me-3' style={{fontSize:"calc(0.5rem + 1vw)"}}> HOURS</span>
                                            {minute}
                                            <span className='saira text-light-blue me-lg-5 me-3' style={{fontSize:"calc(0.5rem + 1vw)"}}> MINUTES</span>
                                            {second}
                                            <span className='saira text-light-blue' style={{fontSize:"calc(0.5rem + 1vw)"}}> SECONDS</span>
                                        </h1>
                                    </div>
                                </div>
                            </div>    
                        </div>
                    </div>

                    <div className='col-lg-4 funnel-container align-self-center'>
                        <div className='row justify-content-center'>

                            {offer === "1"?
                            <>
                                <div className='col-lg-4 col-11 lt-card card-container-blue card-hover p-lg-5 p-4 text-center align-items-center'>
                                        
                                    <p className='pill-white black mb-2'><i className='fa-solid fa-star'/> RECOMMENDED</p>
                                    <h2 className='text-white black'>Best Choice</h2>
                                    <img className='mb-3 w-100' src={offer1} alt='icon'/>

                                    <p className='text-start text-white mb-2 black'>Offer Includes:</p>
                                    <p className='text-start text-white mb-2 bold'><i className='fa-solid fa-circle-check'/> 3 Day Forex Power Course</p>
                                    <p className='text-start text-white mb-2 bold'><i className='fa-solid fa-circle-check'/> One Month Compund Plan</p>
                                    <p className='text-start text-white bold'><i className='fa-solid fa-circle-check'/> Building Empire Strategy</p>

                                    <h3 className='text-center text-white mt-3 black'>₱ 1,997</h3>

                                </div>
                            </>
                            :offer === "2"?
                            <>
                                <div className='col-lg-3 col-10 lt-card card-container card-hover p-5 text-center d-flex align-items-center'>
                                    <div>
                                        
                                        <p className='pill-white black mb-2 invisible'>&nbsp;</p>
                                        <h2>Better Choice</h2>
                                        <img className='mb-3 w-100' src={offer2} alt='icon'/>
                                        
                                        <p className='text-start text-light-blue mb-2 bold'>Offer Includes:</p>
                                        <p className='text-start text-light-blue mb-2'><i className='fa-solid fa-circle-check'/> 3 Day Forex Power Course</p>
                                        <p className='text-start text-light-blue mb-2'><i className='fa-solid fa-circle-check'/> One Month Compund Plan</p>
                                        <p className='text-start text-light-blue mb-2 bold'>&nbsp;</p>

                                        <h3 className='text-center text-light-blue mt-3 bold'>₱ 1,497</h3>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className='col-lg-3 col-10 lt-card card-container card-hover p-5 text-center align-items-center d-flex'>
                                    <div>
                                        
                                        <p className='pill-white black mb-2 invisible'>&nbsp;</p>
                                        <h2>Good Choice</h2>
                                        <img className='mb-3 w-100' src={offer3} alt='icon'/>
                                        
                                        <p className='text-start text-light-blue mb-2 bold'>Offer Includes:</p>
                                        <p className='text-start text-light-blue mb-2'><i className='fa-solid fa-circle-check'/> 3 Day Forex Power Course</p>
                                        <p className='text-start text-light-blue mb-2 bold'>&nbsp;</p>
                                        <p className='text-start text-light-blue mb-2 bold'>&nbsp;</p>

                                        <h3 className='text-center text-light-blue mt-3 bold'>₱ 997</h3>
                                    </div>
                                </div>

                            </>}

                        </div>

                    </div>

                    <div className='col-lg-6 funnel-container align-self-center mt-lg-0 mt-3'>
                        <div className='row justify-content-center my-4'>

                            <div className='col-12 align-self-center'>
                                <div className='row justify-content-center'>
                                    <div className='col-lg-12 justify-content-center text-center align-self-center'>
                                        <img style={{borderRadius:"100rem"}} src={studentInfo?studentInfo.profile.profilePictureURL:profilePlaceholder} alt='profile' width="75px"/>
                                        <p className='mt-2 text-center text-blue bold'>{studentInfo?studentInfo.profile.name.firstName + ' ' + studentInfo.profile.name.lastName:''}</p>
                                        {studentInfo && studentInfo.eaconomyAccount && <p className='mb-1 text-center pill text-white bold d-inline'><i className='fa fa-star'/> Privileged Member (₱500 OFF)</p>}
                                        <p className='mb-4'>{studentInfo?'Please upload a screenshot of the payment proof so Trading Circle can verify it.':<span> <b>Enroll</b> or <b>Log in</b> to your account to confirm your payment.</span>}</p>
                                        <hr className='mb-4 mt-0'/>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-12 justify-content-center text-center'>

                                {!studentInfo?
                                <>
                                    <div className='row justify-content-center'>
                                        <div className='col-lg-8 col-10 align-items-center'>
                                            <a href={redirectURL} target='_top'><button className='px-3 py-2 btn-solid w-100'> <i className='fa fa-sign-in me-2'/>Enroll / Log In</button></a>
                                        </div>
                                    </div>
                                </>
                                :
                                <div className='row justify-content-center'>
                                    <h3 className='saira bold text-blue'>3 Day Forex Power Course Payment Proof</h3>
                                    <div className='col-12 justify-content-center d-flex'>

                                        <div id='imgProof' className={`card-container ${proofURL !== imagePlaceholder && 'pointer'}`} style={{
                                            backgroundImage:`url('${proofURL}')`,
                                            backgroundPosition:'center center', 
                                            backgroundSize:'cover',
                                            backgroundRepeat:'no-repeat',
                                            width:'500px',height:'300px'}}
                                            onClick={()=>openProof()}
                                        />
                                    </div>
                                    {proofURL !== imagePlaceholder && <span className='mt-1 text-muted small'>*Tap/Click image to view</span>}
                                        
                                    <div className='col-lg-9 col-12 mt-3'>
                                        {canSubmit &&
                                        <>
                                            <input className='form-control' type='file' accept='image/png,image/jpg,image/jpeg' id='fileUploadProof' onChange={()=>uploadProof()}/>
                                            <small id="errProfilePicture" class="form-text text-danger text-center mb-3 mt-2"></small>
                                        </>
                                        }

                                        <>
                                            <h5 className='saira mt-3'>STATUS: <span dangerouslySetInnerHTML={{__html:status}}></span></h5>
                                            <p className='text-error'>{reason}</p>
                                            {status && status.includes('Pending') && <p className='text-success'><i className='fa fa-check'/> Payment Proof Sent</p>}
                                            {status && status.includes('Pending') && <p className='text-muted small'>*You will be notified here and on your email ({studentInfo.profile.emailAddress})</p>}
                                        </>
                                    </div>

                                    
                                </div>
                                }

                            </div>


                            
                        </div>
                    </div>

                    <div className='col-12 funnel-container align-self-center'>

                        <div className='row justify-content-center my-4' style={{rowGap:"30px"}}>
                            <div className='col-lg-6 col-11' data-aos='fade-up'>
                                <img src={gcash} width='80%' alt='logo'/>
                                <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                <h5><b>Account Number:</b> 0929 853 5283</h5>
                                {/*<h5><b>Account Name:</b> Aleli Joy Balingit</h5>
                                <h5><b>Account Number:</b> 0927 577 3805</h5>*/}
                                {/*<h5><b>Account Name:</b> Aljay Balingit</h5>
                                <h5><b>Account Number:</b> 0920 971 0611</h5>*/}
                            </div>

                            <div className='col-lg-6 col-11' data-aos='fade-up'>
                                <img src={bdo} width='80%' alt='logo'/>
                                <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                <h5><b>Account Number:</b> 0056 4034 7652</h5>
                            </div>

                            <div className='col-lg-6 col-11' data-aos='fade-up'>
                                <img src={chinaBank} width='80%' alt='logo'/>
                                <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                <h5><b>Account Number:</b> 1268 0202 1864</h5>
                            </div>

                            <div className='col-lg-6 col-11' data-aos='fade-up'>
                                <img src={coinsPH} width='80%' alt='logo'/>
                                <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                <h5><b>Account Number:</b> 0929 853 5283</h5>
                            </div>

                            <div className='col-lg-6 col-11' data-aos='fade-up'>
                                <img src={landBank} width='80%' alt='logo'/>
                                <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                <h5><b>Account Number:</b> 2796 2042 76</h5>
                            </div>

                            <div className='col-lg-6 col-11' data-aos='fade-up'>
                                <img src={maya} width='80%' alt='logo'/>
                                <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                <h5><b>Account Number:</b> 09298535283</h5>
                            </div>

                            <div className='col-lg-6 col-11' data-aos='fade-up'>
                                <img src={bpi} width='80%' alt='logo'/>
                                <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                <h5><b>Account Number:</b> 4719 3229 51</h5>
                            </div>

                            <div className='col-lg-6 col-11' data-aos='fade-up'>
                                <img src={securityBank} width='80%' alt='logo'/>
                                <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                <h5><b>Account Number:</b> 00000 5691 5885</h5>
                            </div>

                            <div className='col-lg-6 col-11' data-aos='fade-up'>
                                <img src={unionBank} width='80%' alt='logo'/>
                                <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                <h5><b>Account Number:</b> 1094 2909 4115   </h5>
                            </div>


                        </div>
                    </div>

                </div>
            </div>


            <FunnelFooter/>

            <div className='d-lg-block d-none' style={{background:`url('${irregShape}')`,width:'125%',height:'1507px',position:'absolute',top:'-100px',zIndex:'-999',backgroundPosition:'center center',right:'-12.5%',backgroundSize:'cover',opacity:'0.3'}}/>
            <div className='d-lg-none d-block' style={{background:`url('${irregShape}')`,width:'100%',height:'1005px',position:'absolute',top:'-100px',zIndex:'-999',backgroundPosition:'center center',backgroundSize:'cover',opacity:'0.2'}}/>
            
            <div className='d-lg-block d-none' style={{background:`url('${irregShape}')`,rotate:'180deg',width:'125%',height:'1507px',position:'absolute',top:'1200px',zIndex:'-999',backgroundPosition:'center center',right:'-12.5%',backgroundSize:'cover',opacity:'0.3'}}/>
            <div className='d-lg-none d-block' style={{background:`url('${irregShape}')`,rotate:'180deg',width:'100%',height:'1005px',position:'absolute',top:'1200px',zIndex:'-999',backgroundPosition:'center center',backgroundSize:'cover',opacity:'0.2'}}/>

            <ModalLoading show={processing}/>

        </>
    )
}