/* eslint-disable jsx-a11y/anchor-has-content */
import blackWordLogo from '../../img/logo/black-word-logo.png';

export const Header = (props) =>{

    return(

        <div className="row justify-content-center mb-3 mt-3">

            <div className='col-lg-5 col-11 text-center'>
                <img className='d-lg-inline d-none darkmode-logo' src={blackWordLogo} alt='blue logo' width="350px"/>
                <img className='d-lg-none d-inline darkmode-logo' src={blackWordLogo} alt='blue logo' width="75%" style={{maxWidth:'400px'}}/>
                <hr className='blue-hr'/>
                <h2 className='saira bold text-blue' dangerouslySetInnerHTML={{__html:props.title}}></h2>
            </div>

        </div>

    );

}